import { Card, Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import localforage from 'localforage'
import React from 'react'

function Drive() {
  return (
    <MDBox
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '100%',
      width: '100%',
      // paddingTop: '11rem',
    }}
  >
    404 not found
    </MDBox>
    // <Card sx={{ padding: 7, margin: 9 }}>
    //   <Grid container>
    //     <Grid item xs={12} mb={5}>
    //       <MDBox>
    //         <MDButton
    //           variant="contained"
    //           color="info"
    //           onClick={() =>
    //             window.open(
    //               'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive.metadata%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive.metadata.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive.file%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive.file%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive.photos.readonly&include_granted_scopes=true&response_type=code&client_id=343592497166-gstek5pnnmh0v6svml9jl5f4kekledku.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fgdrive.livewiremediapartners.com%2Fintegrations%2Fdrive',
    //               '_self'
    //             )
    //           }
    //         >
    //           Connect Auth
    //         </MDButton>
    //       </MDBox>
    //     </Grid>
    //   </Grid>
    // </Card>
  )
}

export default Drive
