import axios from 'axios'
import env from 'config'
import { ReqMethods, ResponseStatus } from 'enums'
import { useAuth } from './auth'

const BASE_URL = `${env.API_URL}/v1`

function useServiceHandler() {
  return (fn) =>
    async (method, path, { query, payload, token, apiKey, toaster, message, error } = {}) => {
      try {
        const res = await fn(method, path, { query, payload, token, apiKey })
        console.log('API - RESPONSE', res, toaster, message, error)

        // toaster && utils.showToaster({
        //     title    : 'Success',
        //     message  : message || res.data.message,
        //     severity : 'success'
        // })
        return { response: res.data }
      } catch (err) {
        console.log('API- ERROR', err.response?.data || err)

        // expire error : jwt expired
        if (err.response && err.response.status === ResponseStatus.UNAUTHORIZED) {
          // utils.showToaster({
          //     position : 'top-right',
          //     title    : 'Error',
          //     message  : error || 'session expired',
          //     severity : 'error'
          // })
          // setTimeout(utils.Logout, enums.TOASTER_DELAY)
          // Logout()
          return { error: err.response?.data || err }
        }

        // const customError = err.response?.data.error ? `${err.response?.data?.message   } \n${  err.response?.data?.error}` : err.response?.data?.message

        // toaster && utils.showToaster({
        //     title    : 'Error',
        //     message  : error || customError || err.message,
        //     severity : 'error'
        // })
        return { error: err.response ? err.response.data : err }
      }
    }
}

function useCallService() {
  const authToken = useAuth()
  const serviceHandler = useServiceHandler()

  const CallService = (method, path, { query, payload, token = true, apiKey = null }) => {
    const pathname = query ? `${path}?${query}` : path
    const config = {}

    if (token) config.headers = { 'x-auth-token': `Bearer ${authToken || token}` }
    if (apiKey) config.headers = { apiKey }

    const details = {}

    if (payload) details.payload = payload
    details.config = config

    return axios[method](pathname, ...Object.values(details))
  }

  return serviceHandler(CallService)
}

function useAppServices() {
  const { GET, POST,PUT } = ReqMethods
  const CallService = useCallService()

  /**
    Option for service is the object that could has the following properties
    query, payload, token, apiKey
  */

  const APIs = {
    auth: {
      login: (options) => CallService(POST, `${BASE_URL}/auth/login`, options),
    },
    user: {
      get: (options) => CallService(GET, `${BASE_URL}/user`, options),
    },
    drive: {
      get: (options) => CallService(GET, `${BASE_URL}/drive/submit`, options),
      getCreds: (options) => CallService(GET, `${BASE_URL}/drive`, options),
    },
    form: {
      filter: (options) => CallService(GET, `${BASE_URL}/form/filter`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/form`, options),
      get: (options) => CallService(GET, `${BASE_URL}/form`, options),
      delete: (options) => CallService(POST, `${BASE_URL}/form/delete`, options),
    },
    services: {
      ghl: {
        call_service: (options) => CallService(POST, `${BASE_URL}/services/ghl/`, options),
      },
    },
  }

  return APIs
}

export { useAppServices, useCallService }
