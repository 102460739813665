import { Environment } from '../enums'

const env = {
  // API_URL : 'https://dev.walletcampaigns.com/api',
  // API_URL : 'http://35.176.217.108:8080/api'
  API_URL: 'https://driveapi.livewiremediapartners.com/api',
  GHL: {
    CLIENT_ID: '6246e2fcfc3dabe2d3301bc5-l1gcm0ww',
    CLIENT_SECRET: '67be1628-cb7c-4530-95a3-a83e60093cd0',
    SCOPE:
      'contacts.readonly contacts.write locations.readonly locations/customFields.readonly locations/customFields.write workflows.readonly users.readonly users.write campaigns.readonly conversations/message.readonly conversations/message.write locations/customValues.readonly locations/customValues.write opportunities.readonly opportunities.write forms.readonly links.readonly links.write surveys.readonly locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write locations/templates.readonly',
    REDIRECT: {
      ASSOCIATE: 'http://localhost:3000/integrations/ghl/associate',
    },
  },
}

// if(process.env.REACT_APP_ENV === Environment.DEVELOPMENT){
// }

if (process.env.REACT_APP_ENV === Environment.STAGING) {
  env.API_URL = 'https://dev.app-template.com/api'
  env.GHL.REDIRECT.ASSOCIATE = 'https://dev.app-template.com/integrations/ghl/associate'
}

// if(process.env.REACT_APP_ENV === Environment.PRODUCTION){

// }

export default env
