import { Navigate, Outlet } from 'react-router-dom'

import PropTypes from 'prop-types'

export default function RouteGuard({valid, redirect}){
  return valid ? <Outlet /> : <Navigate replace to={redirect} />
}

RouteGuard.propTypes = {
  valid    : PropTypes.bool.isRequired,
  redirect : PropTypes.string.isRequired
}