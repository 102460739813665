import React from "react";
import { Backdrop, Fade, Modal } from "@mui/material";
import MDBox from "components/MDBox";

import PropTypes from "prop-types";

function MDModal({ open, onClose, styling, children }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: styling?.width ? styling?.width : 400,
    minHeight: styling?.minHeight ? styling?.minHeight : 400,
    maxHeight: styling?.maxHeight ? styling?.maxHeight : "",
    overflow: styling?.overflow ? styling?.overflow : "",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <MDBox sx={style}>{children}</MDBox>
      </Fade>
    </Modal>
  );
}

MDModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  styling: PropTypes.object,
};

export default MDModal;
