import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { useAppServices } from 'hook/services'
import localforage from 'localforage'
// import localforage from 'localforage'

function GHL() {
  const navigate = useNavigate()
  const Service = useAppServices()
  const { search } = useLocation()
  const code = new URLSearchParams(search).get('code')

  console.log(code)
  const [Error, setError] = useState('')
  const getCreds =async () => {
    if (code) {
        // const token = await localforage.getItem('token')
    const { response,error } = await Service.drive.get({query: `code=${code}`})
     console.log(response,error)
     if(response.data){
        localforage.setItem('auth',response.data)
        navigate(-2)

     }else{
        setError(error)

     }
    }
  }

  const onLoad = () => {
    getCreds()
  }

  useEffect(onLoad, [])

  return (
    <MDBox>
      {!Error && <MDBox>Integrating please wait...</MDBox>}
      {Error && (
        <MDBox>
          {Error}
          <MDButton onClick={() => navigate(-2)}>Go Back</MDButton>
        </MDBox>
      )}
    </MDBox>
  )
}

export default GHL
