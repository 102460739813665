/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate, useParams } from 'react-router-dom'

// @mui material components
import Card from '@mui/material/Card'

// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'

// Images
import { useEffect, useState } from 'react'
import { useAppServices } from 'hook/services'
import Loader from 'Loader'
import { Alert, Checkbox, Button, Stack, Switch, TextField } from '@mui/material'
import './style.css'
import livewirelogo from 'assets/images/livewire-logo.jpeg'

function DefaultForm({ creads, form }) {
  const [processing, setProcessing] = useState(false)
  const [editnames, seteditnames] = useState(false)
  const [error, seterror] = useState('')
  const [response, setresponse] = useState('')
  const AppService = useAppServices()
  const Navigate = useNavigate()
  const [selectedFiles, setSelectedFiles] = useState([])
  const params = useParams()
  console.log(form, 'formformformformformformformformformform')
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files)

    console.log(files, 'files')

    setSelectedFiles(files)
  }

  const getURL = (file) => {
    let url = window.URL.createObjectURL(file)
    return url
  }
  const WebhookEventHandler = async (webhook, data) => {
    const axios = require('axios')
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: webhook,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    }

    const response = await axios
      .request(config)
      .then((response) => {
        console.log('Webhook successfully called \n', webhook)
        return {
          success: true,
          data: response.data,
        }
      })
      .catch((error) => {
        console.log('error calling webhook \n', webhook)
        return {
          success: false,
          data: error.data,
        }
      })
  }
  async function uploadFile(e) {
    e.preventDefault()
    setProcessing(true)
    var responses = []
    var errors = []
    var res = await selectedFiles.forEach(async (element) => {
      const file = element
      // alert(file_name)
      var metadata = {}
      if (editnames) {
        const file_name = e.target[file.name].value

        metadata = {
          name: file_name,
          mimeType: file.type,
          parents: [form[0].folder_id],
        }
      } else {
        metadata = {
          name: file.name,
          mimeType: file.type,
          parents: [form[0].folder_id],
        }
      }

      const formData = new FormData()
      formData.append(
        'metadata',
        new Blob([JSON.stringify(metadata)], { type: 'application/json' })
      )
      formData.append('file', file)
      const token = creads
      console.log(token, 'token')
      // return 0
      fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          responses.push(data)
          console.log('File uploaded successfully:', data)
          setresponse('File uploaded successfully')
          setTimeout(() => {
            setresponse('')
          }, 3000)
        })
        .catch((error) => {
          errors.push(error)
          console.error('Error uploading file:', error)
          seterror('Error uploading file')
          setTimeout(() => {
            seterror('')
          }, 3000)
        })
    })
    // if (res) {
    const payload = {
      responses: responses,
      errors: errors,
      no_of_files: selectedFiles.length,
      folder_id: form[0].folder_id,
      location_name: form[0].name,
      location_id: form[0].locationId,
    }
    console.log(payload, 'payloadpayloadpayloadpayload')
    console.log(form[0].webhook, 'form.webhook')
    await WebhookEventHandler(form[0].webhook, payload)
    e.target.images.value = null
    setSelectedFiles([])
    setProcessing(false)
    seteditnames(false)
    // }
  }
  return (
    <MDBox display="flex" justifyContent="center" flexDirection="column" mt={3}>
      <Card sx={{ minWidth: 390, maxWidth: 400, marginTop: 6, marginBottom: '0.5rem' }}>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {form[0]?.name || ''}
          </MDTypography>
          <MDTypography variant="block" sx={{ fontSize: '1.3rem' }} color="white" mt={1}>
            Drive Form
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={uploadFile}>
            <MDBox mb={1} ml={1}>
              <MDTypography variant="h6" fontWeight="medium">
                upload Image/video *
              </MDTypography>
            </MDBox>
            <MDBox mb={2} ml={1} display="flex" alignItems="center">
              <Stack direction="row" alignItems="center" spacing={2}>
                <Button variant="contained" component="label" sx={{ color: '#fff' }}>
                  Upload +
                  <input hidden multiple name="images" type="file" onChange={handleFileChange} />
                </Button>
              </Stack>
              {selectedFiles.length != 0 ? (
                <MDTypography variant="button" color="text" fontWeight="medium" ml={2}>
                  you select {selectedFiles.length} files*
                </MDTypography>
              ) : (
                ''
              )}
            </MDBox>
            <MDBox display="flex" alignItems="center">
              {selectedFiles.length != 0 ? (
                <>
                  <MDTypography variant="button" fontWeight="medium" ml={2}>
                    Edit Names
                  </MDTypography>
                  <Switch onClick={(e) => seteditnames(e.target.checked)} />
                </>
              ) : (
                ''
              )}
            </MDBox>
            <MDBox>
              {editnames ? (
                <>
                  {selectedFiles.map((data) => (
                    <>
                      {data.type == 'video/mp4' ? (
                        <video src={getURL(data)} width="320" height="240" controls></video>
                      ) : (
                        <img src={getURL(data)} style={{ height: '150px' }} />
                      )}

                      <TextField
                        type="text"
                        name={data.name}
                        defaultValue={data.name}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                      />
                    </>
                  ))}
                </>
              ) : (
                ''
              )}
            </MDBox>

            {/* <MDBox mb={2} ml={1}>
              <input type="file" name="images" multiple onChange={handleFileChange} />
            </MDBox> */}
            {error != '' ? (
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="success">{error}</Alert>
              </Stack>
            ) : (
              <></>
            )}
            {response != '' ? (
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="success">{response}</Alert>
              </Stack>
            ) : (
              <></>
            )}
            <MDBox mt={2} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                loading={processing}
                disabled={processing}
                sx={{ mt: 1, mb: 1 }}
                fullWidth
              >
                Save
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </MDBox>
  )
}

function Form() {
  const AppService = useAppServices()
  const Navigate = useNavigate()
  const params = useParams()
  const [creads, setcreads] = useState({})
  const [form, setform] = useState([])
  const [loader, setloader] = useState(true)
  const getCreds = async () => {
    const { response, error } = await AppService.drive.getCreds()
    console.log(response, error)
    if (response) setcreads(response.data)
  }
  const getForm = async () => {
    const { response, error } = await AppService.form.filter({
      query: `locationId=${params.locationId}`,
    })
    console.log(response, error)
    if (response) setform(response.data)
    setloader(false)
  }

  const onLoad = () => {
    getCreds()
    getForm()
  }

  useEffect(onLoad, [])

  return loader ? (
    <Loader />
  ) : (
    <MDBox
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '100%',
        width: '100%',
        // paddingTop: '11rem',
      }}
    >
      {form.length != 0 ? (
        <>
          <img
            className="main-logo"
            src={livewirelogo}
            style={{ position: 'absolute', top: '1.5rem', left: '3rem', borderRadius: '20px' }}
            width={150}
            alt=""
          />
          <DefaultForm creads={creads} form={form} />
        </>
      ) : (
        '404 not found'
      )}
    </MDBox>
  )
}

export default Form
