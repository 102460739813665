import { useUserInfo } from 'context/user'
import localforage from 'localforage'

function useAuth() {
  const [user] = useUserInfo()
  return user?.token
}

function useLogout() {
  const clear = useUserInfo()[2]
  return () => {
    localforage.clear()
    clear()
  }
}

export { useAuth, useLogout }
