import MDBox from 'components/MDBox'
import React, { useEffect, useState } from 'react'
import MUIDataTable from 'mui-datatables'
import { useAppServices } from 'hook/services'
import MDButton from 'components/MDButton'
import { CopyAll, Delete, Done, Edit, RemoveRedEye, ViewAgenda } from '@mui/icons-material'
import { Button, Card, Grid } from '@mui/material'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import EditModal from './EditModal'

const EditFormlist = () => {
  const AppService = useAppServices()
  const [form, setform] = useState([])
  const [copy, setcopy] = useState([])
  const [form_id, setform_id] = useState('')

  const getForm = async () => {
    const { response, error } = await AppService.form.get()
    console.log(response, 'response')
    if (response) setform(response.data)
  }
  const handleRefresh = () => {
    getForm()
  }
  const handlecopy = (e) => {
    setform_id(e)
    setcopy(true)

    setTimeout(() => {
      setcopy(false)
    }, 3000)
  }
  const handleDelete = async (id) => {
    console.log(id)
    const { response } = await AppService.form.delete({
      query: `_id=${id}`,
    })
    if (response) getForm()
  }
  const onLoad = () => {
    getForm()
  }

  useEffect(onLoad, [])
  const Conjo = []
  form.forEach((element) => {
    const temp = [
      element.name,
      element.folder_id,
      element.locationId,
      <MDBox>
        <CopyToClipboard
          text={'https://gdrive.livewiremediapartners.com/form/' + element.locationId}
          onCopy={() => handlecopy(element._id)}
        >
          {element._id == form_id ? (
            copy ? (
              <Done fontSize="medium" sx={{ cursor: 'pointer' }} />
            ) : (
              <CopyAll fontSize="medium" sx={{ cursor: 'pointer' }} />
            )
          ) : (
            <CopyAll fontSize="medium" sx={{ cursor: 'pointer' }} />
          )}
        </CopyToClipboard>
        ,
        <EditModal data={element} handleRefresh={handleRefresh} />
        <Delete
          sx={{ cursor: 'pointer' }}
          fontSize="medium"
          onClick={() => handleDelete(element._id)}
        />
      </MDBox>,
    ]
    Conjo.push(temp)
  })
  const options = {
    filter: true,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: 'none',
    filterType: 'multiselect',
    responsive: 'standard',
  }
  const columns = [
    {
      name: 'Name',
      options: {
        filter: false,
      },
    },
    {
      name: 'Folder Id',
      options: {
        filter: false,
      },
    },
    {
      name: 'Location Id',
      options: {
        filter: false,
      },
    },
    {
      name: 'Action',
      options: {
        filter: false,
      },
    },
  ]
  return (
    <>
      <MDBox display="flex" justifyContent="flex-end" mt={2} mr={2}> 
        <MDButton
          variant="contained"
          color="info"
          onClick={() =>
            window.open(
              'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive.metadata%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive.metadata.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive.file%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive.file%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive.photos.readonly&include_granted_scopes=true&response_type=code&client_id=343592497166-gstek5pnnmh0v6svml9jl5f4kekledku.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fgdrive.livewiremediapartners.com%2Fintegrations%2Fdrive',
              '_self'
            )
          }
        >
          Connect Auth
        </MDButton>
      </MDBox>
      <MDBox className="form-padding">
        <MUIDataTable title={'Form List'} data={Conjo} columns={columns} options={options} />
      </MDBox>
    </>
  )
}

export default EditFormlist
