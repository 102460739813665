import MDButton from 'components/MDButton'
import React, { useEffect, useState } from 'react'
import MDModal from 'components/MDModal'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { Edit } from '@mui/icons-material'
import { TextField } from '@mui/material'
import { useAppServices } from 'hook/services'

const EditModal = ({data,handleRefresh}) => {
  const [openAddProduct, setOpenAddProduct] = useState(false)
  const AppService = useAppServices()

  var axios = require('axios')

  const handlemodal = () => {
    setOpenAddProduct(true)
  }
  useEffect(async () => {}, [])
  function AddProduct({ open, onClose }) {
    const [processing, setProcessing] = useState(false)

    const handleSubmit = async (e) => {
      e.preventDefault()
        setProcessing(true)
        const payload={
            _id:data._id,
            name:e.target.name.value,
            folder_id:e.target.folder_id.value,
            locationId:e.target.locationId.value,
            webhook:e.target.webhook.value,
        }
        console.log(payload)

        const { response } = await AppService.form.update({
            payload: payload,
          });
          console.log(response)
          if(response){
        setProcessing(false)
        onClose()
        handleRefresh()
          }
    }
    return (
      <MDModal open={open} onClose={onClose} styling={{width:400,minHeight:480}}>
        <MDBox >
          <MDBox component="form" onSubmit={handleSubmit} role="form" >
            <MDTypography variant="h5" mb={2}>Edit Form</MDTypography>
            <MDBox>
            <MDTypography variant="button"  sx={{fontSize:'14px',fontWeight:"500"}} mb={1}>Name</MDTypography>
            <MDBox>
                <TextField type='text' defaultValue={data.name} name='name' fullWidth/>
            </MDBox>
            </MDBox>
            <MDBox>
            <MDTypography variant="button"  sx={{fontSize:'14px',fontWeight:"500"}} mb={1}>Folder Id</MDTypography>
            <MDBox>
                <TextField type='text' defaultValue={data.folder_id} name='folder_id' fullWidth/>
            </MDBox>
            </MDBox>
            <MDBox>
            <MDTypography variant="button"  sx={{fontSize:'14px',fontWeight:"500"}} mb={1}>Location Id</MDTypography>
            <MDBox>
                <TextField type='text' defaultValue={data.locationId} name='locationId' fullWidth/>
            </MDBox>
            </MDBox>
            <MDBox>
            <MDTypography variant="button"  sx={{fontSize:'14px',fontWeight:"500"}} mb={1}>Webhook</MDTypography>
            <MDBox>
                <TextField type='text' defaultValue={data.webhook} name='webhook' fullWidth/>
            </MDBox>
            </MDBox>
            <MDBox display="flex" justifyContent="flex-end">
              <MDButton
                variant="gradient"
                color="primary"
                type="button"
                sx={{ mt: 4, mb: 1 }}
                onClick={closeAddProduct}
              >
                close
              </MDButton>
              <MDButton variant="gradient" color="info" type="submit" sx={{ mt: 4, mb: 1, ml: 1 }}  loading={processing}
          disabled={processing}>
                Edit
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDModal>
    )
  }
  const closeAddProduct = (subscription) => {
    // if (subscription?._id)
    setOpenAddProduct(false)
  }
  return (
    <>
      <Edit sx={{ cursor: 'pointer', marginX: 1 }} fontSize="medium"  onClick={handlemodal}/>

      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
    </>
  )
}

export default EditModal
