import MDBox from 'components/MDBox'
import React, { useEffect, useState } from 'react'
import MUIDataTable from 'mui-datatables'
import { useAppServices } from 'hook/services'
import MDButton from 'components/MDButton'
import { CopyAll, Done, RemoveRedEye, ViewAgenda } from '@mui/icons-material'
import { Button } from '@mui/material'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const Formlist = () => {
  const AppService = useAppServices()
  const [form, setform] = useState([])
  const [copy, setcopy] = useState([])
  const [form_id, setform_id] = useState('')

  const getForm = async () => {
    const { response, error } = await AppService.form.get()
    console.log(response, 'response')
    if (response) setform(response.data)
  }
  const handlecopy = (e) => {
    setform_id(e)
    setcopy(true)

    setTimeout(() => {
      setcopy(false)
    }, 3000)
  }
  const onLoad = () => {
    getForm()
  }

  useEffect(onLoad, [])
  const Conjo = []
  form.forEach((element) => {
    const temp = [
      element.name,
      <MDBox>
        <RemoveRedEye
        sx={{cursor:'pointer',marginRight:2}}
          fontSize="medium"
          onClick={() =>
            window.open(
              'https://gdrive.livewiremediapartners.com/form/' + element.locationId,
              '_blank'
            )
          }
        />
        <CopyToClipboard
          text={'https://gdrive.livewiremediapartners.com/form/' + element.locationId}
          onCopy={() => handlecopy(element._id)}
          sx={{ mr: 2 }}
        >
          {element._id==form_id ?
          
          copy ? <Done fontSize="medium"  sx={{cursor:'pointer'}} /> : <CopyAll fontSize="medium"  sx={{cursor:'pointer'}} />
          :
          <CopyAll fontSize="medium"  sx={{cursor:'pointer'}} />
  }
        </CopyToClipboard>
      </MDBox>,
      'https://gdrive.livewiremediapartners.com/form/' + element.locationId,
    ]
    Conjo.push(temp)
  })
  const options = {
    filter: true,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows:'none',
    filterType: 'multiselect',
    responsive: 'standard',
  }
  const columns = [
    {
      name: 'Name',
      options: {
        filter: false,
      },
    },
    {
      name: 'Action',
      options: {
        filter: false,
      },
    },
  ]
  return (
    <MDBox className="form-padding">
      <MUIDataTable title={'Form List'} data={Conjo} columns={columns} options={options} />
    </MDBox>
  )
}

export default Formlist
